import { DateFormatType } from '@app/enums';
import { addDays, parse } from 'date-fns';
import mapValues from 'lodash-es/mapValues';
import sortBy from 'lodash-es/sortBy';
import { createSelector } from 'reselect';

import { PlanType } from '../../../+authenticated/+reports/shared/subscriptions/subscription.model';
import { format, periodFilter } from '../../../shared/date.helper';
import { Totals } from '../../../shared/interfaces';
import { hasAtleastSubscriptionPlan } from '../../../shared/subscription-plan/subscription-plan.directive';
import { defaultTotal, totalAccumulator } from '../../../shared/total.helper';
import { getAccountSubscription } from '../../account/account.service';
import { PermissionState } from '../../auth/auth.model';
import { getAuthenticatedUserId } from '../../auth/auth.service';
import { hasPermission } from '../../auth/permission.helper';
import { AppState } from '../../index';
import { mapEntities, mapEntity } from '../../shared/entity.helper';
import { DepartmentModel } from '../department/department.model';
import { getDepartmentEntities } from '../department/department.service';
import { EmployeeModel } from '../employee/employee.model';
import { getEmployeeEntities } from '../employee/employee.service';
import { LocationModel } from '../location/location.model';
import { getLocationEntities } from '../location/location.service';
import { PermissionOption } from '../permission/permission.model';
import { ScheduleModel } from '../schedule/schedule.model';
import { getSchedulesForAuthenticatedUser } from '../schedule/schedule.service';
import { ShiftModel } from '../shift/shift.model';
import { getShiftEntities } from '../shift/shift.service';
import { TeamModel } from '../team/team.model';
import { getTeamEntities } from '../team/team.service';
import { EmployeeStatusModel, OpenShiftModel, OpenShiftUserStatus } from './open-shift.model';
import { openShiftAdapter, OpenShiftState } from './open-shift.state';

export const getOpenShiftState = (appState: AppState): OpenShiftState => appState.orm.openshifts;

export const { selectAll, selectEntities, selectIds, selectTotal } = openShiftAdapter.getSelectors(getOpenShiftState);

export const enhanceOpenShift =
  (state, teamEntities, shiftEntities, departmentEntities, locationEntities, authenticatedUserId, employeeEntities) =>
  (openShift) => {
    const team = teamEntities[openShift.team_id] ? teamEntities[openShift.team_id] : ({} as TeamModel);
    const shift = shiftEntities[openShift.shift_id] ? shiftEntities[openShift.shift_id] : ({} as ShiftModel);
    const departmentId = team.department_id || shift.department_id;
    const department = departmentEntities[departmentId] ? departmentEntities[departmentId] : ({} as DepartmentModel);
    const locationId = department.location_id;
    const location =
      locationId && !!locationEntities[locationId] ? locationEntities[locationId] : ({} as LocationModel);

    const startDateTime = parse(openShift.date + ' ' + openShift.starttime, 'yyyy-MM-dd HH:mm:ss', new Date());
    let endDateTime = parse(openShift.date + ' ' + openShift.endtime, 'yyyy-MM-dd HH:mm:ss', new Date());

    if (endDateTime <= startDateTime) {
      endDateTime = addDays(endDateTime, 1);
    }

    const employeeStatus: EmployeeStatusModel[] = openShift?.EmployeeStatus ?? [];

    const invitedEmployeeIds = employeeStatus.map((empStatus) => empStatus.employee_id);
    const rejections = employeeStatus
      .filter((empStatus) => empStatus.status === OpenShiftUserStatus.DECLINED)
      .map((empStatus) => empStatus.employee_id);
    const isInvited = invitedEmployeeIds.includes(authenticatedUserId);
    const invitedEmployees =
      invitedEmployeeIds.length > 0 ? sortBy(mapEntities(invitedEmployeeIds, employeeEntities), ['order', 'name']) : [];
    const Rejections =
      rejections.length > 0
        ? invitedEmployees.filter((invitedEmployee: EmployeeModel) => rejections.includes(invitedEmployee.id))
        : [];
    const createdBy =
      openShift.created_by && employeeEntities[openShift.created_by] ? employeeEntities[openShift.created_by].name : '';

    return {
      ...openShift,
      department_id: departmentId,
      Team: team,
      Shift: shift,
      department_name: department && department.name ? department.name : '',
      location_name: location && location.name ? location.name : '',
      startDateTime,
      endDateTime,
      invitees: invitedEmployeeIds,
      isInvited,
      createdBy,
      Invitees: invitedEmployees,
      Rejections,
    };
  };

export const getEnhancedOpenShiftEntities = createSelector(
  getAccountSubscription,
  selectEntities,
  getTeamEntities,
  getShiftEntities,
  getDepartmentEntities,
  getLocationEntities,
  getAuthenticatedUserId,
  getEmployeeEntities,
  (
    accountSubscription,
    openShiftEntities,
    teamEntities,
    shiftEntities,
    departmentEntities,
    locationEntities,
    authenticatedUserId,
    employeeEntities,
  ) => {
    if (!hasAtleastSubscriptionPlan(PlanType.BASIC, accountSubscription)) {
      return {};
    }

    return mapValues(openShiftEntities, (openShift) =>
      enhanceOpenShift(
        openShiftEntities,
        teamEntities,
        shiftEntities,
        departmentEntities,
        locationEntities,
        authenticatedUserId,
        employeeEntities,
      )(openShift),
    );
  },
);
export const getEnhancedOpenShifts = createSelector(
  selectIds,
  getEnhancedOpenShiftEntities,
  (ids, enhancedOpenShifts) =>
    mapEntities(ids, enhancedOpenShifts).filter((openShift) => openShift.instances_remaining > 0),
);

export const getOpenShift = (occurrenceId: string) =>
  createSelector(getEnhancedOpenShiftEntities, (entities) => mapEntity(occurrenceId, entities));

export const getOpenShiftWithSidebar = (occurrenceId: string) =>
  createSelector(getEmployeeEntities, getEnhancedOpenShiftEntities, (employeeEntities, openShiftEntities) => {
    const entity = mapEntity(occurrenceId, openShiftEntities);

    /*
    The openShift could be gone at this point due to optimistic updates
    changing the id of the openShift. In that case simply return `undefined`
    to prevent `entity.created_by` from creating null pointer exceptions.
   */
    if (entity === undefined) {
      return undefined;
    }

    return {
      ...entity,
      CreatedBy: employeeEntities[entity.created_by],
    };
  });

export const hasOpenShiftPermission =
  (permissions: PermissionOption, permissionState: PermissionState) => (openShift: OpenShiftModel) => {
    const check = {
      permissions,
      userId: openShift.isInvited ? permissionState.userId : undefined,
      departments: openShift.department_id,
    };

    return hasPermission(check, permissionState);
  };

export const sumOpenShifts = (openShifts: OpenShiftModel[]): Totals => {
  if (!openShifts || openShifts.length === 0) {
    return defaultTotal;
  }

  return openShifts.reduce((acc, openShift: OpenShiftModel) => {
    const openShiftTotal = {
      hours: parseFloat(openShift.total) * parseInt(openShift.instances, 10),
      pay: 0,
      coc: 0,
    };

    return totalAccumulator(acc, openShiftTotal);
  }, defaultTotal);
};

export const getOpenShiftsForAuthenticatedUser = createSelector(
  getAuthenticatedUserId,
  getEnhancedOpenShifts,
  getSchedulesForAuthenticatedUser,
  (userId: string, openShifts: OpenShiftModel[], schedules: ScheduleModel[]): OpenShiftModel[] =>
    openShifts.filter((openShift) => canTakeOpenShift(userId, openShift, schedules)),
);

export const getOpenShiftsForAuthenticatedUserWithinPeriod = (minDate, maxDate) =>
  createSelector(getOpenShiftsForAuthenticatedUser, (openShifts: OpenShiftModel[]) =>
    openShifts.filter(periodFilter(minDate, maxDate)),
  );

export const canTakeOpenShift = (userId: string, openShift: OpenShiftModel, schedules: ScheduleModel[]): boolean => {
  if (!openShift.EmployeeStatus.some((user) => user.employee_id === userId)) {
    return false;
  }

  return !schedules.some(
    (schedule) =>
      openShift.date === schedule.date &&
      openShift.start_seconds < schedule.end_seconds &&
      openShift.end_seconds > schedule.start_seconds,
  );
};

export const getOpenShiftsForAuthenticatedUserWithoutRejections = createSelector(
  getOpenShiftsForAuthenticatedUser,
  (openShifts: OpenShiftModel[]): OpenShiftModel[] => {
    const today = format(new Date(), DateFormatType.DEFAULT);
    return openShifts.filter((openShift) => !openShift.hasRejected && openShift.date >= today);
  },
);
